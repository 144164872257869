import { CallToAction } from 'components/CallToAction'
import { pineappleText } from 'components/Job/Sidebar'
import { ContributorImage } from 'components/PostLayout/Contributors'
import TeamRoadmap from 'components/TeamRoadmap'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import slugify from 'slugify'
import { SectionWrapper } from './Section'
import { IRoadmap } from './types'

export default function Roadmap({ subtitle, team }: IRoadmap) {
    const {
        team: { nodes },
    } = useStaticQuery(graphql`
        query {
            team: allMdx(
                filter: { fields: { slug: { regex: "/^/team//" } } }
                sort: { fields: frontmatter___startDate }
            ) {
                nodes {
                    frontmatter {
                        headshot {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        team
                        jobTitle
                        name
                        country
                        github
                        teamLead
                        pineappleOnPizza
                    }
                }
            }
        }
    `)
    const teamMembers = nodes
        .filter((node: any) => node?.frontmatter?.team?.some((teamName: any) => teamName === team))
        .sort((l: any, r: any) => (l.frontmatter.teamLead ? -1 : r.frontmatter.teamLead ? 1 : 0))
    const teamLength = teamMembers?.length
    if (!teamMembers || !teamLength) return null
    const pineapplePercentage =
        teamLength &&
        teamLength > 0 &&
        Math.round(
            (teamMembers.filter(({ frontmatter: { pineappleOnPizza } }: any) => pineappleOnPizza).length / teamLength) *
                100
        )
    const teamURL = `/our-teams/${slugify(team, { lower: true })}`
    return (
        <div id="roadmap">
            <SectionWrapper>
                <div className="mt-8 flex flex-col items-start space-y-4 md:flex-row md:space-y-0 md:space-x-8">
                    <div className="flex-1">
                        <h3 className="m-0">Roadmap</h3>
                        <p className="">{subtitle}</p>
                        <TeamRoadmap team={team} />
                    </div>
                    <div className="basis-[350px]">
                        <h4 className="m-0">The {team.toLowerCase()} team</h4>

                        <p className="mb-2 text-sm">Here are the people bringing you {team.toLowerCase()}. </p>

                        <p className="mb-4 text-xs opacity-75">{pineappleText(pineapplePercentage)}</p>
                        <ul className="m-0 mb-4 list-none space-y-2 p-0 md:space-y-0">
                            {teamMembers.map((member: any) => {
                                const { name, headshot, jobTitle, teamLead, country } = member?.frontmatter
                                return (
                                    <li className="flex items-center space-x-2 py-1" key={name}>
                                        <figure className="mb-0">
                                            <ContributorImage
                                                className="h-[45px] w-[45px]"
                                                image={getImage(headshot)}
                                            />
                                        </figure>
                                        <div>
                                            <span className="flex items-center space-x-2 md:flex-row">
                                                <p className="m-0 text-base font-bold leading-none">{name}</p>
                                                {country && (
                                                    <span className="!leading-none">
                                                        {country === 'world' ? (
                                                            '🌎'
                                                        ) : (
                                                            <ReactCountryFlag svg countryCode={country} />
                                                        )}
                                                    </span>
                                                )}
                                                {teamLead && (
                                                    <span className="inline-block rounded-sm border-2 border-red/50 bg-white px-2 py-1 text-[12px] font-semibold !leading-none text-red">
                                                        Team lead
                                                    </span>
                                                )}
                                            </span>
                                            <p className="!mb-0 !mt-1 !text-sm !leading-none opacity-50">{jobTitle}</p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>

                        <CallToAction size="sm" to={teamURL} type="outline">
                            Learn more about this team
                        </CallToAction>
                    </div>
                </div>
            </SectionWrapper>
        </div>
    )
}
