import React from 'react'
import { SectionWrapper } from './Section'
import { IPairGridProps, IPairItem } from './types'
import Link from 'components/Link'
import { FeatureDescription, FeatureTitle } from './Feature'
import { getTailwindGridCol } from './util'

const PairItem = ({ title, Icon, description, className = '', icon, url }: IPairItem) => {
    return (
        <li className={` ${className}`}>
            <Link
                to={url ?? ''}
                className="relative block h-full rounded-md bg-white p-6 pb-8 text-primary shadow hover:top-[-.25px] hover:scale-[1.02] hover:text-primary active:top-[.5px] active:scale-[1] md:mx-4"
            >
                {Icon && <Icon className="mb-4 h-8 w-8 opacity-75" />}
                <FeatureTitle className="text-red dark:text-primary">{title}</FeatureTitle>
                <FeatureDescription className="text-primary/75">{description}</FeatureDescription>
            </Link>
        </li>
    )
}

export default function PairGrid({ features, className = '' }: IPairGridProps) {
    const length = features?.length ?? 1
    return (
        <SectionWrapper className="max-w-full">
            <ul
                className={`max-w-screen-4xl m-0 mx-auto list-none space-y-4 p-0 md:grid md:space-y-0 ${getTailwindGridCol(
                    length
                )} ${className}`}
            >
                {features?.map((feature) => {
                    return <PairItem key={feature?.title} {...feature} />
                })}
            </ul>
        </SectionWrapper>
    )
}
