import React from 'react'
import GithubSlugger from 'github-slugger'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { CallToAction } from 'components/CallToAction'
import Link from 'components/Link'
import { IDocumentation } from './types'
import { prepareData } from 'utils/googleData'

export default function Documentation({ documentation, tutorials, ...props }: IDocumentation) {
    const slugger = new GithubSlugger()
    const { enable, title, subtitle, direction, url, description, cta2, products, svgImage, gatsbyImage, imageStyles } =
        prepareData({ props })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    return (
        <div className="mx-auto max-w-2xl">
            <div className="mb-4 flex items-end justify-between border-b border-dashed border-gray-accent-light pb-4">
                <h2 className="m-0">Docs & resources</h2>
                <CallToAction size="sm" type="secondary" to={documentation?.indexURL}>
                    Visit docs
                </CallToAction>
            </div>
            <div>
                <h4 className="m-0 opacity-60">{title} docs</h4>
                <ul className="m-0 grid list-none divide-y-1 divide-dashed divide-gray-accent-light p-0">
                    {documentation?.pages?.map(({ frontmatter, fields, headings }: any, index: number) => {
                        const slug = fields?.slug
                        return (
                            <li key={slug} className="flex space-x-4 py-4">
                                <span className="mt-1.5 ml-1.5 text-sm font-bold opacity-60">{index + 1}.</span>
                                <div className="w-full">
                                    <Link
                                        to={slug}
                                        className="relative -ml-2 block rounded-sm px-2 py-1 text-sm font-semibold  hover:scale-[1.01] hover:bg-gray-accent-light hover:text-red active:top-[.5px] active:scale-[1]"
                                    >
                                        <h5 className="m-0 text-[17px]">{frontmatter.title}</h5>
                                    </Link>
                                    <ul className="m-0 list-none p-0">
                                        {headings
                                            .filter(({ depth }: any) => depth <= 3)
                                            .slice(0, 3)
                                            .map(({ value }: any) => {
                                                const id = slugger.slug(value)
                                                return (
                                                    <li key={id} className="">
                                                        <Link
                                                            to={`${slug}#${id}`}
                                                            className="relative -ml-2 block rounded-[3px] px-2 py-1 text-sm font-semibold text-primary/75 hover:scale-[1.01] hover:bg-gray-accent-light hover:text-primary active:top-[.5px] active:scale-[1]"
                                                        >
                                                            {value}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div>
                <h4 className="m-0 mt-6 opacity-60 md:mt-12">Tutorials</h4>
                <ul className="m-0 list-none p-0">
                    {tutorials?.map(({ frontmatter, fields }: any) => {
                        const title = frontmatter?.title
                        const slug = fields?.slug
                        return (
                            <li
                                className="list-none border-t border-dashed border-gray-accent-light py-[2px] first:border-t-0"
                                key={slug}
                            >
                                <Link
                                    className="relative block rounded-sm py-2 px-1 font-semibold hover:scale-[1.01] hover:bg-gray-accent-light active:top-[.5px] active:scale-[1]"
                                    to={slug}
                                >
                                    {title}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
