import { CallToAction } from 'components/CallToAction'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionWrapper } from './Section'
import { ICTA } from './types'
import { prepareData } from 'utils/googleData'

export default function CTA(props: ICTA) {
    const { enable, title, subtitle, direction, url, description, cta2, products, svgImage, gatsbyImage, imageStyles } =
        prepareData({ props })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    return (
        <SectionWrapper direction={direction}>
            <div className="flex flex-col justify-between space-y-4 rounded-lg bg-gray-accent-light p-12 dark:bg-primary md:flex-row md:items-center md:space-y-0 md:space-x-4">
                <div>
                    <h2 className="m-0">{title}</h2>
                    <p className="m-0 mb-6">{subtitle}</p>
                    <div className="flex items-center space-x-2">
                        <CallToAction to={url}>{title}</CallToAction>
                        {cta2?.title !== '' && (
                            <CallToAction type="secondary" to={cta2?.url}>
                                {cta2?.title} .
                            </CallToAction>
                        )}
                    </div>
                </div>
                {gatsbyImage && (
                    <div className="md:max-h-[200px] md:max-w-[400px]">
                        <GatsbyImage alt={title} image={gatsbyImage} />
                    </div>
                )}
                {svgImage && !gatsbyImage && (
                    <div className="md:max-w-[400px]">
                        <img alt={title} src={svgImage} />
                    </div>
                )}
            </div>
        </SectionWrapper>
    )
}
