import { MDXProvider } from '@mdx-js/react'
import Layout from 'components/Layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { Check as CheckIcon, Close2 as CloseIcon } from '../components/Icons'
import Hero from 'components/ProductLayout/Hero'
import { MainFeatures } from 'components/ProductLayout/Feature'
import { Sections, SectionWrapper } from 'components/ProductLayout/Section'
import Testimonial from 'components/ProductLayout/Testimonial'
import Comparison from 'components/ProductLayout/Comparison'
import BlogPosts from 'components/ProductLayout/BlogPosts'
import Roadmap from 'components/ProductLayout/Roadmap'
import CTA from 'components/ProductLayout/CTA'
import PairsWith from 'components/ProductLayout/PairsWith'
import Documentation from 'components/ProductLayout/Documentation'
import { ProductLayout } from 'components/ProductLayout'
import Tutorials from 'components/ProductLayout/Tutorials'
import { PlanComparison } from 'components/Pricing/PlanComparison'
import Questions from 'components/ProductLayout/Questions'
import Customers from 'components/ProductLayout/Customers'
import SampleFlow from 'components/ProductLayout/SampleFlow'
import ProductSlider from 'components/ProductLayout/ProductSlider'
import HeroCondensed from 'components/ProductLayout/HeroCondensed'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import Link from 'components/Link'
import { StaticImage } from 'gatsby-plugin-image'
import PostLayout from 'components/PostLayout'
import { Platform } from 'components/NotProductIcons'
import { getSeoImage } from 'utils/googleData'
import { PropertyManagement, RealestateMarketing, RealestateCrm, Websites } from 'components/ProductIcons'

const Check = (props: any) => <CheckIcon {...props} className="mx-auto w-5" />
const Close = (props: any) => <CloseIcon {...props} className="mx-auto w-5" />
const menu = [
    {
        icon: <PropertyManagement className="w-5" />,
        name: 'Property management',
        url: '/property-management/features',
        children: [
            { name: 'Features', url: '/property-management/features' },
            { name: 'Pricing', url: '/property-management/pricing' },
            { name: 'Customers', url: '/property-management/customers' },
            { name: 'Comparisons', url: '/property-management/comparisons' },
            { name: 'Docs', url: '/property-management/documentation' },
            { name: 'Tutorials', url: '/property-management/tutorials' },
            { name: 'Roadmap', url: '/property-management/roadmap' },
            { name: 'Questions', url: '/property-management/questions' },
        ],
        Receipt: <StaticImage width={450} src="../images/receipt.png" />,
    },
    {
        icon: <RealestateMarketing className="w-5" />,
        name: 'Real estate marketing',
        url: '/real-estate-marketing/features',
        children: [
            { name: 'Features', url: '/real-estate-marketing/features' },
            { name: 'Pricing', url: '/real-estate-marketing/pricing' },
            { name: 'Customers', url: '/real-estate-marketing/customers' },
            { name: 'Comparisons', url: '/real-estate-marketing/comparisons' },
            { name: 'Roadmap', url: '/real-estate-marketing/roadmap' },
            { name: 'Docs', url: '/real-estate-marketing/documentation' },
            { name: 'Tutorials', url: '/real-estate-marketing/tutorials' },
            { name: 'Questions', url: '/real-estate-marketing/questions' },
        ],
        Receipt: <StaticImage src="../images/receipt.png" />,
    },
    {
        icon: <RealestateCrm className="w-5" />,
        name: 'Real estate crm',
        url: '/real-estate-crm/features',
        children: [
            { name: 'Features', url: '/real-estate-crm/features' },
            { name: 'Pricing', url: '/real-estate-crm/pricing' },
            { name: 'Customers', url: '/real-estate-crm/customers' },
            { name: 'Comparisons', url: '/real-estate-crm/comparisons' },
            { name: 'Docs', url: '/real-estate-crm/documentation' },
            { name: 'Tutorials', url: '/real-estate-crm/tutorials' },
            { name: 'Roadmap', url: '/real-estate-crm/roadmap' },
            { name: 'Questions', url: '/real-estate-crm/questions' },
        ],
        Receipt: <StaticImage src="../images/receipt.png" />,
    },
    {
        icon: <Websites className="w-5" />,
        name: 'Real estate websites',
        url: '/real-estate-websites/features',
        children: [
            { name: 'Features', url: '/real-estate-websites/features' },
            { name: 'Pricing', url: '/real-estate-websites/pricing' },
            { name: 'Customers', url: '/real-estate-websites/customers' },
            { name: 'Comparisons', url: '/real-estate-websites/comparisons' },
            { name: 'Docs', url: '/real-estate-websites/documentation' },
            { name: 'Tutorials', url: '/real-estate-websites/tutorials' },
            { name: 'Roadmap', url: '/real-estate-websites/roadmap' },
            { name: 'Questions', url: '/real-estate-websites/questions' },
        ],
        Receipt: <StaticImage src="../images/receipt.png" />,
    },
    {
        icon: <Platform className="w-5" />,
        name: 'Real estate platform',
        url: '/real-estate-platform/features',
        children: [
            { name: 'Features', url: '/real-estate-platform/features' },
            { name: 'Pricing', url: '/real-estate-platform/pricing' },
            { name: 'Comparisons', url: '/real-estate-platform/comparisons' },
            { name: 'Roadmap', url: '/real-estate-platform/roadmap' },
            { name: 'Questions', url: '/real-estate-platform/questions' },
        ],
        Receipt: <StaticImage src="../images/receipt.png" />,
    },
]

const Footer = ({ location }) => {
    const currentMenu = menu.find(({ url }) => location.pathname.startsWith('/' + url.split('/')[1]))
    return (
        currentMenu?.children &&
        currentMenu?.children?.length > 0 && (
            <>
                <section className="-mx-5 mt-8 px-5 md:mx-0 md:px-0">
                    <h4>More about {currentMenu.name.toLowerCase()}</h4>
                    <ul className="relative m-0 flex w-full overflow-x-auto px-0 pb-2.5 after:absolute after:top-0 after:w-full after:border-dashed after:border-gray-accent-light  md:pb-0 after:md:border-t">
                        {currentMenu?.children?.map(({ name, url }) => {
                            const active = location.pathname.startsWith(url)
                            return (
                                <li
                                    className="group relative z-20 mb-1.5 flex items-center whitespace-nowrap rounded pt-0.5 text-sm tracking-[-.1px] !text-primary/75 [font-variation-settings:_'wght'_700] hover:scale-[1.01] hover:border-gray-accent-light active:scale-[.99]"
                                    key={url}
                                >
                                    <Link
                                        className={`${
                                            active
                                                ? 'font-bold text-red before:bg-red'
                                                : ' relative mt-1 rounded-sm font-normal text-primary/75 hover:bg-gray-accent-light hover:text-primary'
                                        } relative border-solid px-4 py-1.5 before:absolute before:-top-1 before:left-0 before:h-[2px] before:w-full`}
                                        to={url}
                                    >
                                        {name}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </section>
                {/* <div className="">{currentMenu.Receipt}</div> */}
            </>
        )
    )
}
// export const Head = ({ location, params, data: { pageData }, pageContext: { documentationNav } }) => (
//     <SEO2
//         title={pageData?.frontmatter?.title + ''}
//         description={pageData?.frontmatter?.description || pageData?.excerpt}
//         image={`/images/product/${pageData?.fields?.slug?.split('/')[1]}.png`}
//     />
// )

export default function Product({ data, location, pageContext, children }) {
    const { pageData, blogPosts, documentation, tutorials, customers } = data
    const {
        body,
        excerpt,
        parent,
        fields: { slug },
    } = pageData
    const {
        title,
        subtitle,
        heroDescription,
        description,
        showNav,
        showFooter,
        productFeatures,
        productSections,
        productTestimonial,
        productTeam,
        productMainCTA,
        productPricingCTA,
        productPairsWith,
        productHero,
        sampleFlow,
        productSliderSettings,
        productSlides,
        productDocumentation,
        productBlog,
        seoTitle,
        seoDescription,
        seoimage,
    } = pageData?.frontmatter
    const heroImgObject = productHero?.image?.childImageSharp ? productHero : parent?.cover
    const defaultProductBlogTitle = `Blog posts that mention ${title}`
    const components = {
        Hero: (props: any) => (
            <Hero
                {...props}
                googleImages={[parent?.images, parent?.images2]}
                customers={customers}
                svgImage={productHero?.svgImage}
            />
        ),
        HeroCondensed: (props: any) => (
            <HeroCondensed
                {...props}
                mainCTA={productMainCTA}
                pricingCTA={productPricingCTA}
                googleImages={[parent?.images, parent?.images2]}
                title={title}
                subtitle={subtitle}
                image={productHero}
            />
        ),
        ProductSlider: (props: any) => (
            <ProductSlider
                {...props}
                productSlides2={productSlides}
                productSliderSettings2={productSliderSettings}
                googleImages={[parent?.images, parent?.images2]}
                title={title}
            />
        ),
        MainFeatures: (props: any) => (
            <MainFeatures {...props} googleImages={[parent?.images, parent?.images2]} features2={productFeatures} />
        ),
        Sections: (props: any) => <Sections {...props} googleImages={[parent?.images, parent?.images2]} />,
        Testimonial: (props: any) => (
            <Testimonial
                {...props}
                googleImages={[parent?.images, parent?.images2]}
                productTestimonial2={productTestimonial}
            />
        ),
        Check,
        Close,
        // SampleFlow: (props: any) => <SampleFlow {...props} {...sampleFlow}  />,
        Comparison: (props: any) => (
            <Comparison
                {...props}
                googleImages={[parent?.images, parent?.images2]}
                description={`How does iCloudReady ${title} compare?`}
            />
        ),
        BlogPosts: (props: any) => (
            <BlogPosts
                {...props}
                title={productBlog?.title || defaultProductBlogTitle}
                googleImages={[parent?.images, parent?.images2]}
                posts={blogPosts?.edges}
            />
        ),
        Roadmap: (props: any) => (
            <Roadmap
                {...props}
                team={productTeam}
                googleImages={[parent?.images, parent?.images2]}
                subtitle={`Here's what the ${productTeam} Team is building next.`}
            />
        ),
        CTA: (props: any) => (
            <CTA
                {...props}
                googleImages={[parent?.images, parent?.images2]}
                title={productMainCTA?.title}
                subtitle={productMainCTA?.subtitle}
                image={productMainCTA?.image}
                svgImage={productMainCTA?.svgImage}
                mainCTA={productMainCTA}
                pricingCTA={productPricingCTA}
            />
        ),
        PairsWith: (props: any) => (
            <PairsWith {...props} googleImages={[parent?.images, parent?.images2]} products2={productPairsWith} />
        ),
        Documentation: (props: any) => (
            // <div id="documentation">
            <SectionWrapper {...props}>
                <Documentation
                    {...props}
                    documentation={{
                        indexURL: pageContext?.documentationNav?.url,
                        pages: documentation?.nodes,
                    }}
                    googleImages={[parent?.images, parent?.images2]}
                    title={title}
                    // tutorials={tutorials?.nodes}
                />
            </SectionWrapper>
            // </div>
        ),
        Tutorials: (props: any) => (
            <Tutorials googleImages={[parent?.images, parent?.images2]} tutorials={tutorials?.nodes} />
        ),
        PlanComparison: (props: any) => (
            <div className="mx-auto max-w-screen-2xl">
                <PlanComparison {...props} googleImages={[parent?.images, parent?.images2]} showCTA={false} />
            </div>
        ),
        Questions,
        Customers: (props: any) => (
            <Customers
                {...props}
                googleImages={[parent?.images, parent?.images2]}
                initialCustomer={location.state?.customer}
                customers={customers?.nodes.sort(
                    (a, b) =>
                        pageContext?.customerURLs.indexOf(a.fields.slug) -
                        pageContext?.customerURLs.indexOf(b.fields.slug)
                )}
            />
        ),
    }
    // console.log(`SEO Image /images/product/${pageData?.fields?.slug?.split('/')[1]}.png`)
    return (
        <Layout>
            <SEO
                title={seoTitle || pageData?.frontmatter?.title + ''}
                description={seoDescription || pageData?.frontmatter?.description || pageData?.excerpt}
                image={
                    getSeoImage({ googleImages: [parent?.images, parent?.images2] }) ||
                    `/images/product/${pageData?.fields?.slug?.split('/')[1]}.png`
                }
            />
            <ProductLayout title={title} showNav={showNav} showFooter={showFooter}>
                {/* <PostLayout
                menu={menu}
                title={title}
                hideSidebar
                hideSearch
                hideSurvey
                darkMode={false}
                contentContainerClassName="px-5 lg:px-6 xl:px-12 w-full transition-all mx-auto"
            > */}
                <MDXProvider components={components}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
                {/* <Footer location={location} />
                </PostLayout> */}
            </ProductLayout>
        </Layout>
    )
}

export const query = graphql`
    query Product(
        $id: String!
        $blogTags: [String!]!
        $tutorialTags: [String!]!
        $documentationURLs: [String!]!
        $customerURLs: [String]
    ) {
        pageData: mdx(id: { eq: $id }) {
            body
            excerpt
            fields {
                slug
            }
            frontmatter {
                title
                subtitle
                description
                seoTitle
                seoDescription
                seoimage
                productSlides {
                    label
                    url
                    image
                }
                productSliderSettings {
                    dots
                    infinite
                    arrows
                    slidesToScroll
                    autoplay
                    variableWidth
                    autoplaySpeed
                    speed
                    cssEase
                }
                showNav
                showFooter
                productTeam
                productBlog {
                    title
                }
                productMenuItems
                productHero {
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    width
                    height
                    svgImage
                }
                productPairsWith {
                    title
                    description
                    icon
                    url
                }
                productMainCTA {
                    title
                    subtitle
                    url
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    svgImage
                }
                productPricingCTA {
                    title
                    url
                }
                productTestimonial {
                    author {
                        name
                        role
                        image
                        companyName
                        companyImage
                        company {
                            name
                            image
                        }
                    }
                    quote
                    image
                    imageFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    svgImage
                }
                productFeatures {
                    title
                    description
                    icon
                }
                productSections {
                    title
                    subtitle
                    content
                    align
                    sections {
                        features {
                            title
                            description
                        }
                    }
                    features {
                        title
                        description
                    }
                    image
                    imageFrame
                    imageMaxWidth
                    imageMaxHeight
                    svgImage
                    imageFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            parent {
                ... on GoogleDocs {
                    images {
                        name
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    cover {
                        image {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        blogPosts: allMdx(
            filter: { fields: { slug: { regex: "/^/blog/" } }, frontmatter: { tags: { in: $blogTags } } }
            limit: 6
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        tutorials: allMdx(
            filter: { fields: { slug: { regex: "/^/tutorials/" } }, frontmatter: { tags: { in: $tutorialTags } } }
            limit: 6
        ) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                }
            }
        }
        documentation: allMdx(filter: { fields: { slug: { in: $documentationURLs } } }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                }
                headings {
                    depth
                    value
                }
            }
        }
        customers: allMdx(filter: { fields: { slug: { in: $customerURLs } } }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    customer
                    title
                    logo {
                        publicURL
                    }
                }
                body
            }
        }
    }
`
