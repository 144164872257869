import React from 'react'
import Link from 'components/Link'
import { SectionWrapper } from './Section'
import { IFeature } from './types'
import PairGrid from './PairGrid'
import { prepareData } from 'utils/googleData'

export default function PairsWith(props: { products: IFeature[] }) {
    const { enable, title, subtitle, direction, description, cta, products, svgImage, gatsbyImage, imageStyles } =
        prepareData({ props })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    return (
        <div id="pairs-with">
            <SectionWrapper direction={direction} className="max-w-full">
                <h2 className="mb-2 text-center text-4xl">{title}</h2>
                <p className="mb-0 text-center text-lg  font-semibold">
                    {' '}
                    <Link to={cta?.url}>{cta?.title}</Link>.
                </p>
                <PairGrid features={products} />
            </SectionWrapper>
        </div>
    )
}
